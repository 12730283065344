import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useHeaderStyle from '~styles/useHeaderStyle';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import RegisterButton from '~containers/landing/RegisterButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '106px',
    paddingBottom: '190px',
    gap: 60,
    [theme.breakpoints.down('xs')]: {
      gap: 15,
      paddingTop: '60px',
      paddingBottom: '130px'
    }
  },
  section: {
    position: 'relative',
    backgroundColor: props => props.background_color
  },
  shapeDivider: {
    paddingBottom: '40px'
  }
}));

const WatchNow = ({ title, background_color, text_color, cta_theme, bottom_shape_divider }) => {
  const classes = useStyles({ background_color });
  const headerStyle = useHeaderStyle({ color: text_color, fontSize: '57px' });

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <Typography variant="h2" className={headerStyle.h2}>
          {title}
        </Typography>
        <RegisterButton
          size="large"
          variant={cta_theme}
          analyticsTitle={title}
          analyticsSection="Large Text CTA"
        />
      </div>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

WatchNow.propTypes = {
  title: PropTypes.string,
  background_color: PropTypes.string,
  text_color: PropTypes.string,
  cta_theme: PropTypes.string,
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

WatchNow.defaultProps = {
  title: 'Watch Now',
  background_color: '#F8A39B',
  text_color: '#FFFFFF',
  cta_theme: 'white',
  bottom_shape_divider: null
};

export default WatchNow;
